<template>
    <front-layout>
        <v-container class="fill-height">
            <v-row class="text-center">
                <v-col cols="12">
                    <div>
                        <v-data-table
                            :headers="headers"
                            :items="files"
                            :items-per-page="5"
                            class="elevation-1"
                        >
                            <template v-slot:item.actions="{ item }">
                                <a target="_blank" :href="item.download_url" download>
                                    <v-icon
                                        color="primary"
                                        medium
                                    >
                                        mdi-arrow-down-circle-outline
                                    </v-icon>
                                </a>
                            </template>
                        </v-data-table>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </front-layout>
</template>

<script>
    import FrontLayout from "../../layouts/FrontLayout";
    import {mapState} from 'vuex'
    export default {
        name: "Index",
        data(){
            return {
                headers: [
                    { text: 'Name', value: 'original_name' },
                    { text: 'From', value: 'original_extension' },
                    { text: 'To', value: 'to' },
                    { text: 'Actions', value: 'actions'},
                ]
            }
        },
        computed: {
            ...mapState({
                files: state => {
                    return state.myFiles.items;
                }
            })
        },
        methods: {
            download(item) {
                window.open(item.download_url);
            }
        },
        components: { FrontLayout }
    }
</script>

<style scoped>

</style>
